@import '@/styles/config';

.dataGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @include breakpoint(large, max) {
    grid-template-columns: 1fr;
  }
}